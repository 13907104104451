@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@layer base {
  html,
  body {
    font-family: Montserrat, system-ui, sans-serif;
    overflow: overlay;
    font-size: 16px;
    line-height: 24px;
    color: #312E36;
    font-weight: 500;
  }
}

.wordSpace {
  word-break: break-word;
  letter-spacing: 4px;
  line-height: 14px;
  font-size: 24px;
  -webkit-text-security: disc;
}

/** Custom toast style*/
.Toastify__toast {
  margin-bottom: 16px !important;
}
.Toastify__toast-container {
  padding: 0 !important;
  width: auto !important;
}
.Toastify__toast--error {
  width: 453px !important;
  background: #cf1e24 !important;
}
.Toastify__toast--success {
  width: 453px !important;
  background: #3b994a !important;
}
.Toastify__toast--warning {
  width: 453px !important;
  background: #fff278 !important;
}
.Toastify__toast--info {
  display: flex !important;
  align-items: center !important;
  width: 302px !important;
  background: #09050f !important;
}
.Toastify__close-button {
  margin: 5px 8px !important;
}
.Toastify__toast-body {
  padding-right: 0;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
/* Edge */
input::-ms-reveal {
  display: none;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.ReactModal__Overlay--after-open {
  background-color: rgba(9, 5, 15, 0.25) !important;
  z-index: 99;
  backdrop-filter: blur(3px);
}

.ReactModal__Content--after-open {
  overflow: initial !important;
}

#main {
  padding: 16px;
  border-radius: 8px;
  background-color: #09050f;
  font-weight: 500;
}

abbr {
  text-decoration: none;
}

/*
 *  Custom - scrollbar
 */

::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #cfc9c9;
}

.cus-scrollbar::-webkit-scrollbar-track,
.small-scrollbar::-webkit-scrollbar-track {
  background-color: transparent;
}
.cus-scrollbar::-webkit-scrollbar,
.small-scrollbar::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: transparent;
}
.cus-scrollbar::-webkit-scrollbar-thumb,
.small-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #dededf;
}
.small-scrollbar::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
.tabscroll::-webkit-scrollbar {
  display: none;
}
